//画面サイズごとの処理
$(window).on("load resize", function () {
  const w = $(window).width();
  deviceFunc(w);
});

const browser = browserCheck();
const os = osCheck();
const device = deviceCheck();

$(document).ready(function () {
  /*
  // 画面読み込み時にローディング画面表示
  if ($("#home").length) {
    var webStorage = function () {
      if (sessionStorage.getItem("access")) {
        //2回目以降アクセス時の処理
        $(".loading").addClass("is-active");
      } else {
        //初回アクセス時の処理
        sessionStorage.setItem("access", "true"); // sessionStorageにデータを保存
        $(".loading-animation").addClass("is-active"); // loadingアニメーションを表示
        setTimeout(function () {
          // ローディングを数秒後に非表示にする
          $(".loading").addClass("is-active");
          $(".loading-animation").removeClass("is-active");
        }, 4000); // ローディングを表示する時間
      }
    };
    webStorage();
  }*/

  //ハンバーガーメニュー表示
  var state = false;
  $("#menu-btn").on("click", function () {
    $("#menu").toggleClass("open");
    if (state == false) {
      $("body").addClass("is-fixed");
      state = true;
    } else {
      $("body").removeClass("is-fixed");
      state = false;
    }
  });

  // topへのボタン非表示
  var pagetop = $("#pagetop");
  pagetop.hide();

  pagetop.click(function () {
    $("body, html").animate({ scrollTop: 0 }, 500);
    return false;
  });

  // aimation呼び出し
  if ($(".js-scroll-trigger").length) {
    scrollAnimation();
  }

  // アンカーリンク位置変更（外部から）
  // ページのURLを取得
  const url = $(location).attr("href");
  // headerの高さを取得
  headerHeight = $("header").outerHeight();
  // urlに「#」が含まれていれば
  if (url.indexOf("#") != -1) {
    // urlを#で分割して配列に格納
    const anchor = url.split("#");
    // 分割した最後の文字列（#◯◯の部分）をtargetに代入
    target = $("#" + anchor[anchor.length - 1]);
    // リンク先の位置からheaderHeightの高さを引いた値をpositionに代入
    position = Math.floor(target.offset().top) - headerHeight;
    // positionの位置に移動
    $("html, body").animate({ scrollTop: position }, 500);
  }

  //モーダル表示
  $(".privacy-link").click(function () {
    $("#privacy").addClass("is-show");
  });
  $("#privacy").click(function () {
    $("#privacy").removeClass("is-show");
  });

  // ヘッダーの高さを取得
  var headerH = $("header").outerHeight(true);

  //スクロール操作時実行
  $(window).scroll(function () {
    // ヘッダーの高さを取得
    var headerH = $("header").outerHeight(true);
    // お問い合わせの位置を取得
    if ($("#contact").length) {
      var targetElement = $("#contact");
      var targetPosition = targetElement.offset().top - 400;
      scrollRecaptchaImg(targetPosition);
    }
    headerAdjustHeight(headerH);
  });

  //画面読み込み時実行
  headerAdjustHeight(headerH);
  if ($("#contact").length) {
    var targetElement = $("#contact");
    var targetPosition = targetElement.offset().top - 400;
    scrollRecaptchaImg(targetPosition);
  }

  // #で始まるリンクをクリックした場合（同じページ内）
  $('a[href^="#"]').click(function () {
    // 例えばヘッダーの高さを事前に取得
    var h = $("header").outerHeight(); // 追加
    // adjust に代入するとヘッダーの高さを取得してズラせる。
    var adjust = -h; // 変更
    console.log(adjust);
    // スクロールの速度（ミリ秒）
    var speed = 400;
    // リンク先を取得してhrefという変数に代入
    var href = $(this).attr("href");
    // リンク先にidがある場合ターゲットとして値を取得
    var target = $(href == "#" || href == "" ? "html" : href);
    // ターゲットの位置を取得し、調整がある場合は位置の調整を行う
    var position = target.offset().top + adjust;
    // スクロール実行
    $("body,html").animate({ scrollTop: position }, speed, "swing");
    if (state != false) {
      //ハンバーガーメニューを開いているときの挙動を考慮
      $("#menu").toggleClass("open");
      $("body").removeClass("is-fixed");
      state = false;
    }
    return false;
  });
});

// 特定の位置にきたら、recapchaのロゴを出す
function scrollRecaptchaImg(targetPosition) {
  var currentPosition = $(window).scrollTop();

  // 特定のスクロール位置に達したらCSSを変更
  if (currentPosition >= targetPosition) {
    $(".grecaptcha-badge").css("opacity", "1");
    $(".grecaptcha-badge").css("visibility", "visible");
  } else {
    $(".grecaptcha-badge").css("opacity", "0");
    $(".grecaptcha-badge").css("visibility", "hidden");
  }
}
// aimation関数
function scrollAnimation() {
  // 初期表示時に一度実行
  $(".js-scroll-trigger").each(function () {
    let position = $(this).offset().top,
      scroll = $(window).scrollTop(),
      windowHeight = $(window).height();

    if (scroll > position - windowHeight + 200) {
      $(this).addClass("is-active");
    }
  });
  $(window).scroll(function () {
    $(".js-scroll-trigger").each(function () {
      let position = $(this).offset().top,
        scroll = $(window).scrollTop(),
        windowHeight = $(window).height();

      if (scroll > position - windowHeight + 200) {
        $(this).addClass("is-active");
      }
    });
  });
}

// aimation関数
function pageTopAnime(pagetop) {
  if ($(this).scrollTop() > 500) {
    pagetop.fadeIn();
  } else {
    pagetop.fadeOut();
  }
}

// 画面位置によって色変更
function headerColor() {
  let position;
  let screenWidth = $(window).width();
  let threshold = 559; // 閾値
  if ($("#home").length && screenWidth <= threshold) {
    //home and sp
    position = 330;
  } else if ($("#home").length && screenWidth >= threshold) {
    //home and pc~tb
    position = 585;
  } else if (screenWidth <= threshold) {
    // sub and sp
    position = 230;
  } else {
    // sub and pc~tb
    position = 400;
  }

  if ($(this).scrollTop() > position) {
    $("header").addClass("header-background-color");
  } else {
    $("header").removeClass("header-background-color");
  }
}

// スクロール途中からヘッダーの高さが小さくなる
function headerAdjustHeight(headerH) {
  var scrollPosition = $(window).scrollTop();
  if (scrollPosition >= headerH) {
    $("header").addClass("header-adjust-height");
    $("main").addClass("header-adjust-set");
  } else {
    $("header").removeClass("header-adjust-height");
    $("main").removeClass("header-adjust-set");
  }
}

function deviceFunc(w) {
  const bp = [559, 959, 1280];
  if (w <= bp[0]) {
    //sp
    $(".tel-number").each(function () {
      if (!$(this).parent(".tel-wrap").length) {
        const no = $(this).data("tel");
        $(this).wrap('<a href="tel:' + no + '" class="tel-wrap"></a>');
      }
    });
  } else if (w <= bp[1]) {
    //tb
    $(".tel-wrap .tel-number").each(function () {
      $(this).unwrap();
    });
  } else if (w <= bp[2]) {
    //small pc
  } else {
  }
}
/*ブラウザ判別*/
function browserCheck() {
  const ua = window.navigator.userAgent.toLowerCase();
  const av = window.navigator.appVersion.toLowerCase();
  if (ua.indexOf("edge") != -1) {
    return "edge";
  } else if (ua.indexOf("opera") != -1) {
    return "opera";
  } else if (ua.indexOf("msie") != -1 || ua.indexOf("trident") != -1) {
    $("body").addClass("ie");
    if (av.indexOf("msie 6.") != -1) {
      return "ie6";
    } else if (av.indexOf("msie 7.") != -1) {
      return "ie7";
    } else if (av.indexOf("msie 8.") != -1) {
      return "ie8";
    } else if (av.indexOf("msie 9.") != -1) {
      return "ie9";
    } else if (av.indexOf("msie 10.") != -1) {
      return "ie10";
    } else if (av.indexOf("rv:11.") != -1) {
      return "ie11";
    }
  } else if (ua.indexOf("chrome") != -1) {
    return "chrome";
  } else if (ua.indexOf("safari") != -1) {
    return "safari";
  } else if (ua.indexOf("gecko") != -1) {
    return "firefox";
  } else {
    return false;
  }
}

/*OS判別*/
function osCheck() {
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.indexOf("windows") != -1) {
    if (ua.indexOf("phone") != -1) {
      return "winphone";
    } else {
      return "win";
    }
  } else if (ua.indexOf("iphone") != -1) {
    return "iphone";
  } else if (ua.indexOf("ipad") != -1) {
    return "ipad";
  } else if (ua.indexOf("mac") != -1) {
    return "mac";
  } else if (ua.indexOf("android") != -1) {
    if (ua.indexOf("mobile") != -1) {
      if (ua.indexOf("A1_07") != -1) {
        return "tablet";
      } else if (ua.indexOf("SC-01C") != -1) {
        return "tablet";
      } else {
        return "android";
      }
    } else {
      return "tablet";
    }
  } else {
    return false;
  }
}

/*デバイス判別*/
function deviceCheck() {
  const ua = window.navigator.userAgent.toLowerCase();
  if (
    (ua.indexOf("windows") != -1 &&
      ua.indexOf("touch") != -1 &&
      ua.indexOf("tablet pc") == -1) ||
    ua.indexOf("ipad") != -1 ||
    (ua.indexOf("android") != -1 && ua.indexOf("mobile") == -1) ||
    (ua.indexOf("firefox") != -1 && ua.indexOf("tablet") != -1) ||
    ua.indexOf("kindle") != -1 ||
    ua.indexOf("silk") != -1 ||
    ua.indexOf("playbook") != -1
  ) {
    return "tab";
  } else if (
    (ua.indexOf("windows") != -1 && ua.indexOf("phone") != -1) ||
    ua.indexOf("iphone") != -1 ||
    ua.indexOf("ipod") != -1 ||
    (ua.indexOf("android") != -1 && ua.indexOf("mobile") != -1) ||
    (ua.indexOf("firefox") != -1 && ua.indexOf("mobile") != -1) ||
    ua.indexOf("blackberry") != -1
  ) {
    return "sp";
  } else {
    return "pc";
  }
}
